/* CSS Resets */
a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

/* Fonts */
@font-face {
  font-family: 'Lobster'; /*a name to be used later*/
  src: url('./data/Lobster-Regular.ttf'); /*URL to font*/
}

.site {
  display: flex;
  flex-direction: column;
  font-family: 'Lobster';
}

.site-nav {
  width: 70%;
  align-self: center;
}

.site-nav-links {
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  list-style-type: none;

  padding-bottom: 5px;
  border-bottom: 0.5px solid black;
}

.site-nav-links a {
  padding: 5px;
}

.site-nav-links a:hover, a:active {
  background-color: rgba(186, 184, 184, 0.5);
  border: 0.5px solid rgba(186, 184, 184, 0.5);
}

.releases {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.release-title {
  font-size: x-large;
  text-align: center;
  padding: 10px;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-text {
  padding-top: 50px;
  padding-bottom: 100px;
  font-size: x-large;
}

.new-music {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  text-align: center;
}

.label-logo {
  height: auto;
  width: auto;
  max-width: 300px;
  max-height: 300px;
  align-self: center;
}

.site-footer {
  display: flex;
  flex-direction: row-reverse;
  align-self: center;

  position: fixed;
  left: 50%;
  top: 95%;
  transform: translate(-50%, -95%);
}

.merch-link {
  font-size: large;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 20px;
}

.site-footer-logo {
  height: auto;
  width: auto;
  max-width: 100px;
  max-height: 100px;
}

.release-info {
  display: flex;
  justify-content: space-evenly;
  
  padding-bottom: 40px;
}

.cassette {
  width: 100%;
  align-items: center;
}

.contact-info {
  padding-top: 50px;
  text-align: center;
}

/* Mobile Styling */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {
  .site-nav {
    width: 100%;
  }

  .site-nav-links {
    padding-left: 0px;
  }

  .release-info {
    flex-direction: column;
    padding-bottom: 0px;
  }

  .tracks-section {
    padding-bottom: 20px;
  }

  .releases .site-footer {
    display: flex;
    flex-direction: row-reverse;
    align-self: center;
    position: relative;
    left: 50%;
    top: 95%;
    transform: translate(-160%, 80%);
  }
}